import React, { Component } from 'react';
import { ReactiveBase, DataSearch, ReactiveList, MultiList, SelectedFilters, MultiRange } from '@appbaseio/reactivesearch';
import ReactModal from 'react-modal';
import './App.css';
import DateRange from "@appbaseio/reactivesearch/lib/components/date/DateRange";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalContent: ''
    };
    this.closeModal = this.closeModal.bind(this)
  }

  fulltexts = {};
  closeModal() {
    this.setState({ showModal: false })
  }
  showText(file) {
    this.setState({
      showModal: true,
      modalContent: this.fulltexts[file]
    })
  }

  masse(amount) {
    if(amount === -1.0) return 'Kein Betrag';
    if(amount === -2.0) return 'Nicht gefunden';
    if(amount === -3.0) return 'Nicht eindeutig';
    const nf = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    return nf.format(amount);
  }

  render() {
    const padding = "20px";
    const customStyles = {
      content: {
        top: '5%',
        left: '10%',
        right: '10%',
        bottom: '5%',
      }
    };

    const theme = {
      typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '14px',
      },
    
      colors: {
        textColor: '#272d50',
        primaryTextColor: '#fff',
        primaryColor: '#342c5c',
        titleColor: '#67647d',
        alertColor: '#d9534f',
      }
    };
    
    return (
      <div>
        <ReactiveBase
          theme={theme}
          app="bekanntmachungen"
          url="https://es.wk.iusio.de:443"
          style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: padding, backgroundColor: "#494c72" }}>
            <DataSearch
              componentId="SearchFilter"
              filterLabel="Suchbegriff"
              fuzziness={0}
              debounce={500}
              placeholder="Nach Zuschlagsgrund suchen"
              dataField={["text_full"]}
              highlight={true}
              highlightField="text_full"
              autosuggest={false}
              queryFormat="and"
              showClear={true}
              customHighlight={() => ({
                highlight: {
                  pre_tags: ['<mark>'],
                  post_tags: ['</mark>'],
                  fields: {
                    text: {
                      number_of_fragments: 0,
                    },
                    text_full: {
                      fragment_size: 1500,
                      number_of_fragments: 10,
                    },
                  },

                },
              })}
            />
          </div>

          <div style={{ height: "auto", display: "flex", padding: padding }}>
            <div style={{ width: "25%" }}>
              <MultiList
                filterLabel="Verfahrensart"
                componentId="VArtFilter"
                dataField="inik"
                title="Verfahrensart"
                showSearch={false}
                react={{
                  "and": ["SearchFilter", "GerichtFilter", "MasseFilter", "BtypFilter", "DateSensor"]
                }}
                style={{ marginBottom: padding }}
              />

              <MultiList
                filterLabel="Vergütungsart"
                componentId="BtypFilter"
                dataField="btyp"
                title="Vergütungsart"
                showSearch={false}
                react={{
                  "and": ["SearchFilter", "GerichtFilter", "MasseFilter", "VArtFilter", "DateSensor"]
                }}
                style={{ marginBottom: padding }}
              />

              <MultiList
                filterLabel="Gericht"
                componentId="GerichtFilter"
                dataField="gericht.raw"
                title="Gericht"
                placeholder="Gericht suchen"
                size={200}
                react={{
                  "and": ["SearchFilter", "BtypFilter", "MasseFilter", "VArtFilter", "DateSensor"]
                }}
                style={{ marginBottom: padding }}
              />

              <MultiRange
                  filterLabel="BGL"
                componentId="MasseFilter"
                dataField="masse"
                data={
                  [
                    { "start": -1, "end": -1, "label": "Kein Betrag" },
                    { "start": -2, "end": -2, "label": "Nicht gefunden" },
                    { "start": -3, "end": -3, "label": "Nicht eindeutig" },
                    { "start": 0, "end": 10000, "label": "0 - 10.000 EUR" },
                    { "start": 10000.01, "end": 100000, "label": "10.000 EUR - 100.000 EUR" },
                    { "start": 100000.01, "end": 1000000, "label": "100.000 - 1.000.000 EUR" },
                    { "start": 1000000.01, "end": null, "label": "mehr als 1.000.000 EUR" }]
                }
                title="Berechnungsgrundlage"
                showCheckbox={true}
                URLParams={false}
                style={{ marginBottom: padding }}
              />

              <DateRange
                filterLabel="Datum"
                componentId="DateSensor"
                dataField="date"
                title="Veröffentlichungsdatum"
                numberOfMonths={2}
                initialMonth={new Date('2018-01-01')}
                dayPickerInputProps={{"locale": "de"}}
                style={{ marginBottom: padding }}
              />

              <DataSearch
                  filterLabel="ID"
                componentId="FileFilter"
                title="Interne ID"
                label="ID"
                placeholder="Interne ID"
                dataField={["file"]}
                highlight={false}
                autosuggest={true}
                queryFormat="and"
                showClear={true}
              />

            </div>
            <div style={{ width: "75%", paddingLeft: "40px" }}>
              <SelectedFilters
                showClearAll={true}
                clearAllLabel="Alle Filter entfernen"
                style={{ marginBottom: padding }}
              />
              <ReactiveList
                componentId="SearchResult"
                loader="Suche läuft ..."
                dataField="date"
                sortBy="desc"
                onNoResults="Keine Ergebnisse gefunden."
                sortOptions={[
                  {"label": "Datum absteigend", "dataField": "date", "sortBy": "desc"},
                  {"label": "Datum aufsteigend", "dataField": "date", "sortBy": "asc"},
                  {"label": "Masse absteigend", "dataField": "masse", "sortBy": "desc"},
                  {"label": "Masse aufsteigend", "dataField": "masse", "sortBy": "asc"}
                ]}
                pagination={false}
                size={20}
                react={{
                  "and": ["GerichtFilter", "SearchFilter", "BtypFilter", "FileFilter", "MasseFilter", "VArtFilter", "DateSensor"]
                }}
                onResultStats={(total_results, time_taken) => <div style={{ width: "100%", fontSize: "small", marginBottom: padding }}>
                    {total_results} Ergebnisse gefunden (Dauer: {time_taken} ms)
                  </div>}
                onData={(res) => {
                  
                  this.fulltexts[res.file] = res

                  return <div className="card">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <div className="tag">{res.inik}</div>
                        <div className="tag">{res.btyp}</div>
                        <div className="tag">{res.gericht}</div>
                        <div className="tag">Masse: {this.masse(res.masse)}</div>
                        <div className="tag">{new Date(res.date).toLocaleDateString('de-DE')}</div>
                        <div className="tag">{res.az}</div>
                      </div>
                      <div><button onClick={() => this.showText(res.file)} >Anzeigen</button></div>
                    </div>
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: res.text_full ? res.text_full : res.text.replace(/\n/g, '<br>') }}></span><br />
                    <div className="tag" style={{ background: '#fff', borderStyle: 'dashed', marginTop: padding }}
                    onDoubleClick={() => navigator.clipboard.writeText(res.file)}>{res.file}</div>
                  </div>
                }}
              /></div>
          </div>
        </ReactiveBase>
        <ReactModal
          style={customStyles}
          onRequestClose={this.closeModal}
          isOpen={this.state.showModal}>
          <div className="tag">{this.state.modalContent.inik}</div>
          <div className="tag">{this.state.modalContent.btyp}</div>
          <div className="tag">{this.state.modalContent.gericht}</div>
          <div className="tag">Masse: {this.masse(this.state.modalContent.masse)}</div>
          <div className="tag">{new Date(this.state.modalContent.date).toLocaleDateString('de-DE')}</div>
          <div className="tag">{this.state.modalContent.az}</div>
          <br/><br/>

          <p style={{ fontSize: 14, color: theme.colors.textColor }} dangerouslySetInnerHTML={{ __html: this.state.modalContent && this.state.modalContent.text.replace(/\n/g, '<br>') }} />
          <div className="tag" style={{ background: '#fff', borderStyle: 'dashed', marginTop: padding }}
                    onDoubleClick={() => navigator.clipboard.writeText(this.state.modalContent.file)}>{this.state.modalContent.file}</div>
        </ReactModal></div>
    );
  }
}

export default App;
